import { useMutation } from '@apollo/client';
import { stagingOptions } from 'constants/apollo';
import { MergeOrdersToShipmentPayload } from "api/mutations/ShipmentMutation";
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useMergeOrder = ({ clearUp = () => { }, selectedShipmentIds, selectedShipments, setSelectedShipments }) => {
    const [handleGqlMergeOrder, { data, loading, error }] =
        useMutation(MergeOrdersToShipmentPayload, { ...stagingOptions, fetchPolicy: "network-only" });

    useEffect(() => {
        if (data) {
            try {
                if (data?.mergeOrdersToShipment) {
                    const orderIDs = selectedShipments.map(shipment => shipment.id);
                    const ordersString = orderIDs.join(' ');
                    const shipmentID = data?.mergeOrdersToShipment?.shipments?.[0]?.id;
                    toast.success(`Orders ${ordersString} merged into shipment ${shipmentID}`);
                    setSelectedShipments((prevState) => {
                        const updatedShipments = prevState.map((shipment) => ({
                            ...shipment,
                            shipment: shipment?.shipment
                                ? { ...shipment.shipment, id: shipmentID }
                                : { id: shipmentID },
                        }));
                        return updatedShipments;
                    });
                } else {
                    toast.error(`Something went wrong while merging orders`);
                }
            } catch (error) {
                toast.error(`Something went wrong while merging orders`);
            }
        }
    }, [data])

    function handleMergeOrder() {
        if (selectedShipments.length < 2) {
            toast.info('Select two or more orders to merge into a shipment');
            return;
        }

        try {
            const trackingNos = selectedShipments.map(shipment => shipment.tracking_no);
            toast.info(`Merging orders...`);
            handleGqlMergeOrder({
                variables: {
                    input: { trackingNos }
                }
            })
        } catch (error) {
            toast.error('Something went wrong while processing the request');
        }
    }

    return { handleMergeOrder }
}
