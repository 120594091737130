import { gql } from "@apollo/client";

export const LOAD_CITIES = gql`
	query {
		fetchCities {
			id
		}
	}
`;

// load orders for the map

export const LOAD_ORDERS_FOR_MAP = gql`
	query FetchAllOrders(
		$limit: Int
		$offset: Int
		$cityId: Int
		$orderStatuses: [OrderStatusTypes!]
		$merchantIds: [Int!]
		$hubIds: [Int!]
		$shipmentTypes: [OrderShipmentTypes!]
		$point1Lat: Float
		$point1Long: Float
		$point2Lat: Float
		$point2Long: Float
		$query: String
		$startDate: ISO8601DateTime
		$endDate: ISO8601DateTime
		$isHubActive: Boolean
	) {
		fetchAllOrders(
			limit: $limit
			offset: $offset
			cityId: $cityId
			orderStatuses: $orderStatuses
			merchantIds: $merchantIds
			hubIds: $hubIds
			shipmentTypes: $shipmentTypes
			point1Lat: $point1Lat
			point1Long: $point1Long
			point2Lat: $point2Lat
			point2Long: $point2Long
			query: $query
			startDate: $startDate
			endDate: $endDate
			isHubActive: $isHubActive
		) {
			totalCount
			items {
				tracking_no: trackingNo
				shipment{
                   id
                }
				merchant {
					name
				}
				neighborhood
				orderStatus
				id
				isCompleted
				isActive
				orderStatus
				origin {
					latitude
					longitude
				}
				destination {
					latitude
					longitude
				}
				hub {
					id
					isActive
					isOpen
				}
			}
		}
	}
`;

export const FETCH_ORDER_FOR_MAP = gql`
	query FetchOrder($id: Int!) {
		fetchOrder(id: $id) {
			tracking_no: trackingNo


			id
			neighborhood
			merchant {
				name
			}
			isCompleted
			isActive
			orderStatus
			origin {
				latitude
				longitude
			}
			destination {
				latitude
				longitude
			}
			warehouseDestination {
				city
			}

			hub {
				isOpen
				isActive
				code
				id
			}

			payment_type: paymentType
			invoiceTotal
			customerDetails{
				mobile
			}
			courier {
				firstName
				lastName
				avatarUrl
				id
				latitude
				longitude
			}
			shipment {
				id
			createdAt
				isCancelled
			assignTime
			completeTime
			
			isCompleted
			shipmentStatus
			
			
		}

		}
	}
`;

// shipment status field is not coming

export const LOAD_SHIPMENTS_FOR_MAP = gql`
	query FetchAllShipments(
		$limit: Int
		$offset: Int
		$cityId: Int
		$shipmentStatuses: [Int]
		$hubOpen: Boolean
		$merchantId: Int
		$shipmentType: String
		$ne: String
		$sw: String
		$query: String
		$includeInactive: Boolean
		$readySince: ISO8601DateTime
	) {
		fetchAllShipments(
			limit: $limit
			offset: $offset
			cityId: $cityId
			shipmentStatuses: $shipmentStatuses
			hubOpen: $hubOpen
			merchantId: $merchantId
			shipmentType: $shipmentType
			ne: $ne
			sw: $sw
			query: $query
			includeInactive: $includeInactive
			readySince: $readySince
			
		) {
			items {
				id
				tracking_no: trackingNo
				orders_count: numberOfOrders
				shipmentStatus
				latitude
				longitude
				
				orders {
					id
					isCompleted
					isActive
					orderStatus
					origin {
						latitude
						longitude
					}
					destination {
						latitude
						longitude
					}
				}
			}
			totalCount
		}
	}
`;

export const LOAD_SHIPMENTS = gql`
	query FetchAllShipments(
		$limit: Int
		$offset: Int
		$cityId: Int
		$shipmentStatuses: [Int]
		$hubOpen: Boolean
		$merchantId: Int
		$shipmentType: String
		$ne: String
		$sw: String
		$query: String
		$includeInactive: Boolean
		$readySince: ISO8601DateTime
	) {
		fetchAllShipments(
			limit: $limit
			offset: $offset
			cityId: $cityId
			shipmentStatuses: $shipmentStatuses
			hubOpen: $hubOpen
			merchantId: $merchantId
			shipmentType: $shipmentType
			ne: $ne
			sw: $sw
			query: $query
			includeInactive: $includeInactive
			readySince: $readySince
		) {
			id
			createdAt
			isAssigned
			isCancelled
			assignTime
			completeTime
			tracking_no: trackingNo
			isCompleted
			shipmentStatus
			hub {
				isOpen
				code
				id
			}
			payment_type: paymentType
			orders_count: numberOfOrders
			merchant {
				name
				id
			}
			latitude
			longitude
			courier {
				firstName
				lastName
				avatarUrl
				id
				latitude
				longitude
			}
			orders {
				id
				neighborhood
				isCompleted
				isActive
				orderStatus
				origin {
					latitude
					longitude
				}
				destination {
					latitude
					longitude
				}
				warehouseDestination {
					city
				}
			}
		}
	}
`;

export const LOAD_DRIVERS = gql`
	query FetchAllDrivers(
		$limit: Int
		$offset: Int
		$showBanned: Boolean
		$ne: String
		$sw: String
		$cityId: Int
		$vehicleType: Int
		$nationality: String
		$dress: Int
		$deviceType: String
		$isOnline: Boolean
		$isBusy: Boolean
		$query: String
		$lastSeen: ISO8601DateTime
		$vehicleModel: String
	) {
		fetchAllCouriers(
			limit: $limit
			offset: $offset
			cityId: $cityId
			vehicleType: $vehicleType
			nationality: $nationality
			dress: $dress
			deviceType: $deviceType
			showBanned: $showBanned
			ne: $ne
			sw: $sw
			isOnline: $isOnline
			isBusy: $isBusy
			query: $query
			lastSeen: $lastSeen
			vehicleModel: $vehicleModel
		) {
			totalCount
			items {
				id
				isBusy
				isOnline
				imei
				avatarUrl
				firstName
				lastName
				lastSeen
				city {
					id
					name
				}
				partner {
					id
					name
				}
				activeShipments {
					id
				}
				activeOrders {
					shipment {
						id
					}
				}
				mobileNumber
				emergencyNumber
				activeShipmentsCount
				profile
				longitude
				latitude
				isOnline
				isBanned
				rating
				courierType
				vehicleType
			}
		}
	}
`;

export const LOAD_DRIVERS_FOR_MAP = gql`
	query FetchAllDrivers(
		$limit: Int
		$offset: Int
		$showBanned: Boolean
		$ne: String
		$sw: String
		$cityId: Int
		$vehicleType: Int
		$nationality: String
		$dress: Int
		$deviceType: String
		$isOnline: Boolean
		$isBusy: Boolean
		$query: String
		$lastSeen: ISO8601DateTime
		$vehicleModel: String
	) {
		fetchAllCouriers(
			limit: $limit
			offset: $offset
			cityId: $cityId
			vehicleType: $vehicleType
			nationality: $nationality
			dress: $dress
			deviceType: $deviceType
			showBanned: $showBanned
			ne: $ne
			sw: $sw
			isOnline: $isOnline
			isBusy: $isBusy
			query: $query
			lastSeen: $lastSeen
			vehicleModel: $vehicleModel
		) {
			totalCount
			items {
				id
				isBusy
				isOnline
				imei
				longitude
				latitude
				isOnline
				isBanned
			}
		}
	}
`;

export const GET_ALL_MERCHANTS = gql`
	query ($offset: Int, $limit: Int) {
		fetchAllMerchants(offset: $offset, limit: $limit) {
			value: id
			label: name
		}
	}
`;

export const GET_ALL_COURIER_PARTNERS = gql`
	query ($offset: Int, $limit: Int) {
		fetchAllCourierPartners(offset: $offset, limit: $limit) {
			value: id
			label: name
		}
	}
`;

export const GET_ALL_CITIES = gql`
	query ($offset: Int, $limit: Int) {
		fetchAllCities(offset: $offset, limit: $limit) {
			value: id
			label: name
		}
	}
`;

export const SEARCH_SHIPMENTS = gql`
	query SearchAllShipments($limit: Int, $offset: Int, $query: String!) {
		searchAllShipments(limit: $limit, offset: $offset, query: $query) {
			id
			isAssigned
			isCancelled
			tracking_no: trackingNo
			isCompleted
			hub {
				isOpen
				code
			}
			payment_type: paymentType
			orders_count: numberOfOrders
			merchant {
				name
			}
			latitude
			longitude
			courier {
				firstName
				lastName
				avatarUrl
				id
				latitude
				longitude
			}
			orders {
				id
				neighborhood
				isCompleted
				isActive
				orderStatus
				origin {
					latitude
					longitude
				}
				destination {
					latitude
					longitude
				}
			}
		}
	}
`;

export const FETCH_SHIPMENT_FOR_MAP = gql`
	query FetchShipment($id: Int!) {
		fetchShipment(id: $id) {
			id
			createdAt
			isAssigned
			isCancelled
			assignTime
			completeTime
			tracking_no: trackingNo
			isCompleted
			shipmentStatus
			hub {
				isOpen
				code
				id
				merchant {
					name
					id
				}
			}
			payment_type: paymentType
			payment_amount: potentialReward
			orders_count: numberOfOrders
			latitude
			longitude
			courier {
				firstName
				lastName
				avatarUrl
				id
				latitude
				longitude
			}
			orders {
				id
				neighborhood
				isCompleted
				isActive
				orderStatus
				origin {
					latitude
					longitude
				}
				destination {
					latitude
					longitude
				}
				warehouseDestination {
					city
				}
			}
		}
	}
`;


export const FETCH_SHIPMENT = gql`
	query FetchShipment($id: Int!) {
		fetchShipment(id: $id) {
			id
			isAssigned
			isCancelled
			tracking_no: trackingNo
			isCompleted
			hub {
				isOpen
				code
			}
			payment_type: paymentType
			orders_count: numberOfOrders
			merchant {
				name
			}
			latitude
			longitude
			courier {
				firstName
				lastName
				avatarUrl
				id
				latitude
				longitude
			}
			orders {
				id
				neighborhood
				isCompleted
				isActive
				orderStatus
				origin {
					latitude
					longitude
				}
				merchantOrderId
				merchantTrackingNo
				merchant {
					name
				}
				customerDetails {
					city
					address
				}
				products {
					name
				}
				destination {
					latitude
					longitude
				}
			}
		}
	}
`;

export const FETCH_DRIVER = gql`
	query FetchDriver($id: Int) {
		fetchCourier(id: $id) {
			id
			isBusy
			isOnline
			avatarUrl
			firstName
			lastName
			city {
				name
			}
			activeShipments {
				id
			}
			activeOrders {
				shipment {
					id
				}
			}
			mobileNumber
			emergencyNumber
			activeShipmentsCount
			profile
			longitude
			latitude
			lastSeen
			stcPayQrUrl
			partner {
				name
			}
		}
	}
`;

export const SHIPMENT_WAREHOUSE_SCAN_PROGRESS = gql`
	query ShipmentWarehouseScanProgress($processId: Int!) {
		shipmentWarehouseScanProgress(processId: $processId) {
			pending
			processed
			total
		}
	}
`;

export const FETCH_SHIPMENTS = gql`
	query FetchShipment($trackingNo: String!) {
		fetchShipment(trackingNo: $trackingNo) {
			id
			isAssigned
			isCancelled
			tracking_no: trackingNo
			isCompleted
			hub {
				isOpen
				code
				city
				id
			}
			payment_type: paymentType
			orders_count: numberOfOrders
			merchant {
				name
			}
			latitude
			longitude
			courier {
				firstName
				lastName
				avatarUrl
				id
				latitude
				longitude
			}
			orders {
				id
				neighborhood
				isCompleted
				isActive
				orderStatus
				origin {
					latitude
					longitude
				}
				merchantOrderId
				merchantTrackingNo
				merchant {
					name
				}
				customerDetails {
					city
					address
				}
				products {
					name
				}
				destination {
					latitude
					longitude
				}
				trackingNo
			}
		}
	}
`;

export const FETCH_WAREHOUSES = gql`
	query FetchAllWarehouses {
		fetchAllWarehouses {
			id
			code
		}
	}
`;

export const FETCH_WAREHOUSES_SHIPMENTS = gql`
	query FetchWarehouseShipment {
		fetchWarehouseShipments {
			id
			trackingNo
			createdAt
			deliveryFee
			deliveryFinish
			drivingDistance
			drivingDuration
			trackingNo
			customerDetails {
				address
				city
			}
			sortingBin
			orderStatus
			products {
				brand
			}
			customerDetails {
				city
				address
			}
			merchantOrderId
			merchantTrackingNo
			merchant {
				name
			}
		}
	}
`;

export const FETCH_DRIVERS = gql`
	query FetchAllDrivers($query: String) {
		fetchAllCouriers(query: $query) {
			items {
				id
				isBusy
				isOnline
				avatarUrl
				firstName
				lastName
				city {
					name
				}
				partner {
					name
				}
				activeShipments {
					id
				}
				activeOrders {
					shipment {
						id
					}
				}
				mobileNumber
				emergencyNumber
				activeShipmentsCount
				profile
				longitude
				latitude
				lastSeen
			}
		}
	}
`;

export const FETCH_PARTNERS = gql`
	query FetchAllPartners {
		fetchAllPartners
	}
`;

export const ASSIGN_ORDERS_TO_PARTNER_PROGRESS = gql`
	query AssignOrdersToPartnerProgress($processId: Int!) {
		assignOrdersToPartnerProgress(processId: $processId) {
			pending
			processed
			total
		}
	}
`;

export const FETCH_ALL_ORDERS = gql`
	query FetchAllOrders(
		$limit: Int
		$offset: Int
		$cityId: Int
		$status: String
		$merchantId: Int
		$query: String
		$includeThirdParty: Boolean
		$order: String
		$orderStatus: OrderStatusTypes
		$shipmentType: OrderShipmentTypes
	) {
		fetchAllOrders(
			limit: $limit
			offset: $offset
			cityId: $cityId
			status: $status
			merchantId: $merchantId
			query: $query
			includeThirdParty: $includeThirdParty
			order: $order
			orderStatus: $orderStatus
			shipmentType: $shipmentType
		) {
			items {
				id
				trackingNo
				driving_time: drivingDuration
				orders_distance: drivingDistance
				shipment_reward: deliveryFee
				shipment {
					courier {
						firstName
						lastName
					}
					potentialReward
				}
				hub {
					city
				}
				orderStatus
			}

			totalCount
		}
	}
`;

export const GET_PRODUCTS_AND_MERCHANTS = gql`
	query GetLocations($input: Int) {
		merchants(
			where: { currentInventory: { some: { id: { eq: $input } } } }
		) {
			items {
				id
				name
			}
		}
		products(
			where: { currentInventory: { some: { id: { eq: $input } } } }
		) {
			items {
				id
				name
				category
			}
		}
	}
`;

export const GET_CURR_INV_SERIALS = gql`
	query GetInventoryLogsWithMerchantAndProductId(
		$where: CurrentInventoryFilterInput
	) {
		currentInventory(where: $where) {
			items {
				serials
			}
		}
	}
`;

export const GET_INVENTORY_LOGS_WITH_MERCHANT_AND_PRODUCT_ID = gql`
	query GetInventoryLogsWithMerchantAndProductId(
		$input: CurrentInventoryFilterInput
	) {
		currentInventory(where: $input) {
			items {
				id
				serials
				merchant {
					id
					name
					referenceId
				}
				product {
					name
					id
					sKU
				}
			}
		}
	}
`;

export const GET_LOCATIONS = gql`
	query GetLocations {
		locations {
			items {
				type
				id
				referenceName
				city
				locationCode
			}
		}
	}
`;

export const GET_ORDER = gql`
	query GetOrder($id: Int, $trackingNo: String) {
		fetchOrder(id: $id, trackingNo: $trackingNo) {
			id
			trackingNo

			shipment {
				id
			}
			orderStatus

			createdAt
			updatedAt
			orderType
			notifiedBy

			origin {
				latitude
				longitude
			}
			destination {
				latitude
				longitude
			}

			neighborhood
			orderCost: invoiceTotal
			deliveryFee
			drivingDistance
			deliveryOtp
			pickupOtp
			returnOtp
			addressStatus
			drivingDuration
			codFee
			paymentStatus

			hub {
				city
				code
			}

			shipment {
				courier {
					id
					lastName
					firstName
					isOnline
					mobileNumber
					latitude
					longitude
				}
			}

			customerDetails {
				firstName
				lastName
				mobile
				city
				address
			}
		}
	}
`;

// used for knights page : driver profile
export const GET_DRIVER = gql`
query GetCourier(
	$id: ID!
) {
	fetchCourier(id: $id)
	{
		id
		avatarUrl
		firstName
		lastName

		isOnline
		isBusy
		isBanned
		onProbation
		vehicleType

		joinedAt
		lastSeen
		mobileNumber
		nationality
		latitude
		longitude
		dateOfBirth
		activeShipmentsCount

		email
		idNumber
		passportNo
		passportCountry
		courierType

		bankIbanNo
		bankCountry
		bankSwift
		stcPayQrUrl
		imei

		currentVehicle {
			imei
			model
			plateNo
			vehicleType
		}
		courierBonuses
		{
			bonusType
			amount
			note
			forDate
			appliedBy
		}

		courierExternalIds {
			name
			startedAt
			finishedAt
			id
			value
			status
		}

		vehicleAssignments{
			vehicle {
				model
				plateNo
			}
			from
			to
			reason
		}

		city{
			id
			name
		}

		partner {
			id
			name
		}

		lastNeighborhood {
			name
		}

		wallet {
			balance
		}

		activeShipments {
			trackingNo
			id
			hub {
				code
				city
			}
			orders {
				id
			}
			actualDeliveryDuration
			fullTripDuration
			firstOrderDistanceKm
			potentialReward
			shipmentStatus
		}

	}

}
`
export const GET_DRIVER_DOCS = gql`
query FetchAllCourierDocuments(
	$id: ID!
) {
	fetchAllCourierDocuments(courierId: $id){
	  filename
  id
  serviceUrl
  type
	}
  }`

export const GET_ALL_VEHICLES = gql`
query FetchAllVehicles(
	$limit: Int,
	$offset: Int
) {fetchAllVehicles (limit: $limit, offset: $offset) {
    totalCount
    items {
      id
      model
      vehicleType
	  plateNo
	  imei
    }}
  }
  `

// DATA FOR ORDER
// {fetchOrder(id:2){
// 	id
//    trackingNo

//    shipment{
// 	 id
//    }
//    orderStatus

//    createdAt
//    updatedAt

//    neighborhood
//    orderCost:invoiceTotal
//  deliveryFee
//  drivingDistance
//    deliveryOtp
//    pickupOtp
//    returnOtp
//    addressStatus
//    drivingDuration
//    codFee
//    paymentStatus

//    hub

//    shipment{
//  courier{
//    id
//    lastName
//    firstName
//    isOnline
//    mobileNumber
//  }}

//    customerDetails{
// 	 firstName
// 	 lastName
// 	 mobile
// 	 city
// 	 address
//    }

// 	 }}


