import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";
import { logger } from "../../../helper/logger";

const SelectAsyncPaginate = (
	{
		value,
		onChange,
		loadMore,
		filterName = "Filter",
		placeholder = "Select...",
		isSearchable = false,
		defaultValue,
		getDefaultValue = () => { },
		getOptionValue = (option) => option.value,
		getOptionLabel = (option) => option.label,
		defaultOptions = [{
			label: "All",
			value: "all"
		}],
		cacheUniqs = [],
		isMulti = false,
	},
	ref
) => {
	const [selected, setSelected] = useState()

	// TODO: Implement debounced search
	const loadOptions = async (searchQuery, loadedOptions, { page }) => {
		return await loadMore(searchQuery, page);
	};

	useEffect(async () => {

		let def = await getDefaultValue()
		setSelected(def)
	}, [])

	return (
		<div className='relative w-full'>
			<AsyncPaginate
				maxMenuHeight={200}
				key={"1_async"}
				defaultValue={defaultValue}
				defaultOptions={defaultOptions}
				value={value || selected}
				loadOptions={loadOptions}
				getOptionValue={getOptionValue}
				getOptionLabel={getOptionLabel}
				styles={{
					menuPortal: (base) => ({ ...base, zIndex: 999, backgroundColor: "white" }),
					valueContainer: (base) => ({ ...base, marginLeft: 0, marginTop: "4px", marginBottom: "2px" }),
					control: (base) => ({
						...base,
						minHeight: "40px",
						flexWrap: "wrap",
					}),
				}}
				onChange={(e) => {
					if (isMulti) {
                        e.value = e.map(e => e.value)
                    }
					onChange(e);
					setSelected(e)
				}}
				isSearchable={isSearchable}
				placeholder={placeholder}
				additional={{
					page: 1,
				}}
				selectRef={ref}
				cacheUniqs={cacheUniqs}
				isMulti={isMulti}
			/>
			<div className='absolute w-auto h-4 bg-white -top-[11px] px-1 left-3 text-sm text-gray-500 cursor-default select-none'>
				{filterName}
			</div>
		</div>
	);
};

// SelectAsyncPaginate.propTypes = {
//     regionName: PropTypes.string.isRequired,
//     value: PropTypes.object,
//     onChange: PropTypes.func,
// };

export default React.forwardRef(SelectAsyncPaginate);
