import { useMutation } from '@apollo/client';
import { stagingOptions } from 'constants/apollo';
import { ASSIGN_ORDER, UNASSIGN_ORDER } from 'api/mutations/AssignShipmentMutation';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useUnassignCourier = ({ clearUp = () => { }, selectedShipmentIds, selectedShipments }) => {
    const [handleGqlUnassign, { data, loading, error }] =
        useMutation(UNASSIGN_ORDER, { ...stagingOptions, fetchPolicy: "network-only" });

    useEffect(() => {
        if (data) {
            if (!data?.unassignOrdersFromShipment?.error?.message) {
                toast.success("Order unassigned successfully");
                const { orders, couriers } = data.unassignOrdersFromShipment;
                clearUp({ orders, courier: couriers[0] });
            } else {
                toast.error("Order can not be unassigned");
            }
        }
    }, [data])

    function handleUnassignCourier() {
        if (selectedShipmentIds.length > 0) {
            const confirmed = window.confirm("Are you sure you want to unassign the selected shipments?");
            if (confirmed) {
                let invalidIds = [];
                for (const shipment of selectedShipments) {
                    if (shipment.orderStatus != 'picked_up') {
                        invalidIds.push(shipment.id);
                    }
                }
                if (invalidIds.length == 0) {
                    toast.info("Unassigning order...");

                    let orderIds = []
                    selectedShipmentIds.forEach((shipmentId) => {
                        orderIds.push(Number(shipmentId))
                    });

                    handleGqlUnassign({
                        variables: {
                            input: {
                                orderIds
                            },
                        },
                    })
                } else {
                    toast.error(`Orders ${invalidIds.join(' ')} have no knight to unassign`);
                }
            }
        } else {
            toast.error("Select at least one shipment and driver!");
        }
    }

    return { handleUnassignCourier }
}
