import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import DriverList from "./DriverList";
import ShipmentsList from "./ShipmentsList";
import { toast } from "react-toastify";
import { logger } from "../../helper/logger";
import LeafletMap from "./LeafletMap";
import {
	LOAD_DRIVERS,
	LOAD_ORDERS_FOR_MAP,
} from "../../api/queries/Queries";
import { stagingOptions } from "../../constants/apollo";
import { useSearchParams } from "react-router-dom";
import {
	GET_ALL_CITIES_FOR_FILTER,
	GET_ALL_MERCHANTS_FOR_FILTER,
	GET_ALL_HUBS_FOR_FILTER,
	GET_CITY_FOR_FILTER,
	GET_MERCHANT_FOR_FILTER
} from "../../api/queries/FilterQueries";
import { getDriverFetchFilters, getOrderFetchFilters, getShipmentFetchFilters } from "../../helper/getFetchFilters";
import { defFilters } from "constants/filters";
import { useAssignCourier } from "./hooks/useAssignCourier";
import { useMergeOrder } from "./hooks/useMergeOrder";
import { useUnassignCourier } from "./hooks/useUnassignCourier";
import MapIsTypingContext from "context/MapIsTypingContext";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

export default function OrdersMap() {
	const localStorageFilters = JSON.parse(
		window?.localStorage?.getItem("mapPageFilters") || "{}"
	);
	const [searchParams, setSearchParams] = useSearchParams(
		localStorageFilters || {}
	);
	const [allShipments, setAllShipments] = useState([])
	const [allShipmentsNew, setAllShipmentsNew] = useState([])
	const [allDrivers, setAllDrivers] = useState([])
	const [merchants, setMerchants] = useState([])
	const [hubs, setHubs] = useState([])
	const [cities, setCities] = useState([])
	const [shipmentLoading, setShipmentLoading] = useState(false)
	const [shipmentListLoading, setShipmentListLoading] = useState(false)
	const [driverListLoading, setDriverListLoading] = useState(false)
	const [driverLoading, setDriverLoading] = useState(false)
	const [areaSelectRegion, setAreaSelectRegion] = useState(null)

	const transformFilters = (filters) => {
		const normalizeArray = (value) => (Array.isArray(value) ? value : [value]);
		const getParamValues = (key, defaultValue = ["all"]) => {
			const values = searchParams.getAll(key);
			return values.length ? values : defaultValue;
		};
		return {
			...filters,
			shipmentType: getParamValues("shipmentType"),
			shipmentOrderStatus: getParamValues("shipmentOrderStatus"),
			hubIds: getParamValues("hubIds"),
			driverCity: String(filters.driverCity),
			driverDevice: String(filters.driverDevice),
			driverVehicle: normalizeArray(filters.driverVehicle),
			driverUniform: normalizeArray(filters.driverUniform),
			driverStatus: normalizeArray(filters.driverStatus),
			driverLastSeen: String(filters.driverLastSeen),
		};
	};

	const [productFilters, setProductFilters] = useState(
		transformFilters({
			...defFilters,
			...localStorageFilters,
			...Object.fromEntries(searchParams),
		})
	);

	const [getAllDrivers, { data: fetchedDrivers, error: driversFetchError }] = useLazyQuery(LOAD_DRIVERS, { ...stagingOptions, fetchPolicy: "network-only" });
	const [getAllShipments, { data: fetchedShipments, error: shipmentsFetchError }] = useLazyQuery(LOAD_ORDERS_FOR_MAP, { ...stagingOptions, fetchPolicy: "network-only" });
	const [getMerchants] = useLazyQuery(GET_ALL_MERCHANTS_FOR_FILTER, stagingOptions);
	const [getHubs] = useLazyQuery(GET_ALL_HUBS_FOR_FILTER, stagingOptions);
	const [getCities] = useLazyQuery(GET_ALL_CITIES_FOR_FILTER, stagingOptions);
	const [getMerchant] = useLazyQuery(GET_MERCHANT_FOR_FILTER, stagingOptions);
	const [getCity] = useLazyQuery(GET_CITY_FOR_FILTER, stagingOptions);

	const [shipmentSkip, setShipmentSkip] = useState(0)
	const [driverSkip, setDriverSkip] = useState(0)
	const [shipmentSearchText, setShipmentSearchText] = useState(
		Object.fromEntries([...searchParams]).shipmentSearchText || localStorageFilters?.shipmentSearchText || ""
	);
	const [driverSearchText, setDriverSearchText] = useState(
		Object.fromEntries([...searchParams]).driverSearchText || localStorageFilters?.driverSearchText || ""
	);

	const [selectedDriver, setSelectedDriver] = useState(null);
	const [selectedShipments, setSelectedShipments] = useState([]);
	const [selectedShipmentIds, setSelectedShipmentIds] = useState([]);
	const [selectedDriverId, setSelectedDriverId] = useState(null);
	const [activeShipments, setActiveShipments] = useState([]);
	const [activeDrivers, setActiveDrivers] = useState([]);

	const [totalShipmentCount, setTotalShipmentCount] = useState(0)
	const [totalDriverCount, setTotalDriverCount] = useState(0)
	const [shipmentFilterUsed, setShipmentFilterUsed] = useState(false)

	const { handleAssignCourier } = useAssignCourier({ clearUp, selectedShipmentIds })
	const { handleUnassignCourier } = useUnassignCourier({ clearUp, selectedShipmentIds, selectedShipments, setSelectedShipments })
	const { handleMergeOrder } = useMergeOrder({ clearUp, selectedShipmentIds, selectedShipments, setSelectedShipments })

	const [isInputFocused, setIsInputFocused] = useState(false);
	const handleQueryChange = (obj) => {
		// update router query based on implemented filters
		let mergedObj = { ...Object.fromEntries([...searchParams]), ...obj };
		["shipmentOrderStatus", "shipmentType", "hubIds"].forEach((key) => {
			const values = searchParams.getAll(key);
			if (values.length) {
				mergedObj[key] = values;
			}
		});
		let modified = {};
		Object.keys(mergedObj).map((key) => {
			if (mergedObj[key] != "all" && mergedObj[key] != "") {
				if (key in obj) {
					modified[key] = obj[key];
				} else {
					modified[key] = mergedObj[key];
				}
			}
		});
		for (let key in modified) {
			if (Array.isArray(modified[key])) {
				modified[key] = modified[key].filter(item => item !== 'all');
			}
		}
		setSearchParams(modified);
		localStorage.setItem(
			"mapPageFilters",
			JSON.stringify(modified)
		);
	};

	const [cityIds, setCityIds] = useState([]);

	const [filters, setFilters] = useState({
		shipment: {
			apply: false,
			showOnMap: true,
			sort_order: "asc",
		},
		driver: {
			apply: false,
			showOnMap: true,
		},
		dropoff: {
			showOnMap: false
		}
	});

	const [mapBounds, setMapBounds] = useState({
		point1Lat: 24.75026098689889,
		point1Long: 46.74015820026398,
		point2Lat: 24.688200182518774,
		point2Long: 46.597335934638984,
	});

	const handleMapBoundsChange = (lat1, long1, lat2, long2) => {
		setMapBounds({
			point1Lat: lat1,
			point1Long: long1,
			point2Lat: lat2,
			point2Long: long2,
		});
	}

	const cleanFilters = (filters) => {
		const updatedFilters = { ...filters };
		Object.keys(updatedFilters).forEach((key) => {
			if (Array.isArray(updatedFilters[key])) {
				updatedFilters[key] = updatedFilters[key].filter(value => value !== "all");
			}
		});
		return updatedFilters;
	};

	const fetch_shipments = ({
		skip = shipmentSkip,
		search = shipmentSearchText,
		filters = productFilters,
		load = true,
		limit = 100,
		isShipmentFilterModalActive = false,
	} = {}) => {
		try {
			setShipmentListLoading(true)
			if (load) {
				setShipmentLoading(true)
			}
			if (load == "twice") {
				setShipmentLoading(true)
			}

			const filterObj = getOrderFetchFilters(cleanFilters(filters), isShipmentFilterModalActive);
			let variables = {
				offset: skip,
				query: shipmentSearchText,
				limit: skip + limit,
				...mapBounds,
				...filterObj,
			}

			// Remove map bounds when searching
			if (shipmentSearchText !== "") {
				variables = {
					offset: skip,
					query: shipmentSearchText,
					...filterObj,
				}
			}

			getAllShipments({
				variables: variables
			}).then(() => {
				setShipmentListLoading(false)
				setShipmentLoading(false)
			})
		}
		catch (e) {
			toast.error("Error while loading shipments:\n", e?.message)
			setShipmentLoading(false)
		}
	}

	useEffect(() => {
		if (fetchedShipments) {
			const allShipmentsList = fetchedShipments?.fetchAllOrders?.items || [];
			setTotalShipmentCount(fetchedShipments?.fetchAllOrders?.totalCount)

			if (shipmentSkip > 0) {
				setAllShipments(allShipmentsList)
				setAllShipmentsNew(allShipmentsList)
			} else {
				setAllShipmentsNew(allShipmentsList)
				if (shipmentFilterUsed) {
					setAllShipments(allShipmentsList)
					setShipmentFilterUsed(false)
				}
			}

			setShipmentLoading(false)
		}
	}, [fetchedShipments])

	useEffect(() => {
		setShipmentListLoading(false)
		setShipmentLoading(false)
	}, [shipmentsFetchError])

	useEffect(() => {
		setDriverListLoading(false)
		setDriverLoading(false)
	}, [driversFetchError])

	useEffect(() => {
		handleUpdateShipmentList() // just update no matter what
	}, [allShipmentsNew])

	const handleUpdateShipmentList = () => {
		// go through allShipments list are remove the ones that are not in allShipmentsNewList, 
		// then add other allShipmentsNew list remaining items

		let oldL = [...allShipments]
		let newL = [...allShipmentsNew]

		oldL = oldL.filter(oldShipment => newL.some(newShipment => newShipment.id == oldShipment.id));
		newL = newL.filter(newShipment => !oldL.some(oldShipment => oldShipment.id == newShipment.id));

		setAllShipments([...oldL, ...newL])
	}

	const fetch_drivers = ({
		skip = driverSkip,
		search = driverSearchText,
		filters = productFilters,
		load = true
	} = {}) => {
		try {
			setDriverListLoading(true)
			if (load) {
				setDriverLoading(true)
			}

			let filterObj = getDriverFetchFilters(filters);
			// logger(">>>FILTERS", filters, "\n>>>FILTRATION", filterObj)

			let updatedMapBounds = {
				sw: `${mapBounds.point1Lat}, ${mapBounds.point1Long}`,
				ne: `${mapBounds.point2Lat}, ${mapBounds.point2Long}`
			}
			let variables = {
				offset: skip,
				query: driverSearchText,
				limit: 30,
				...updatedMapBounds,
				...filterObj,
			}

			// Remove map bounds when searching
			if (driverSearchText && driverSearchText !== "") {
				variables = {
					offset: skip,
					query: driverSearchText,
					...filterObj,
				}
			}
			getAllDrivers({
				variables: variables
			}).then(() => {
				setDriverLoading(false)
				setDriverListLoading(false)
			})
		}
		catch (e) {
			toast.error("Error while loading drivers:\n", e?.message)
			setDriverLoading(false)
			setDriverListLoading(false)
		}

	};

	useEffect(() => {
		if (fetchedDrivers) {
			const allDriversList = fetchedDrivers.fetchAllCouriers?.items;
			setTotalDriverCount(fetchedDrivers.fetchAllCouriers?.totalCount)
			if (driverSkip > 0) {
				setAllDrivers((prev) => [
					...prev,
					...allDriversList,
				])

			} else {
				setAllDrivers(allDriversList)
			}

			setDriverLoading(false)
		}
	}, [fetchedDrivers])

	const fetch_merchants = ({ page = 1, searchQuery } = {}) => {
		logger("fetch_merchants", page, searchQuery)

		return getMerchants({
			variables: {
				offset: (page - 1) * 20,
				query: searchQuery
			}
		}).then((res) => {
			setMerchants(res?.data?.fetchAllMerchants)

			return {
				options: res?.data?.fetchAllMerchants,
				hasMore: res?.data?.fetchAllMerchants?.length >= 1,
				additional: {
					page: searchQuery ? 2 : page + 1,
				}
			}
		});
	}

	const fetch_hubs = ({ page = 1, searchQuery, merchantId } = {}) => {

		return getHubs({
			variables: {
				offset: (page - 1) * 20,
				query: searchQuery,
				merchantId: +merchantId,
			}
		}).then((res) => {
			setHubs(res?.data?.fetchAllHubs)
			return {
				options: res?.data?.fetchAllHubs,
				hasMore: res?.data?.fetchAllHubs?.length >= 20,
				additional: {
					page: searchQuery ? 2 : page + 1,
				}
			}
		});
	}

	const fetch_merchant = ({ id } = {}) => {
		return getMerchant({
			variables: {
				id: parseInt(id)
			}
		}).then((res) => {
			let data = res?.data?.fetchMerchant
			return data
		});
	}

	const fetch_cities = ({ page = 1, searchQuery } = {}) => {
		logger("fetch_cities", page, searchQuery)
		return getCities({
			variables: {
				offset: (page - 1) * 20,
				query: searchQuery
			}
		}).then((res) => {
			setCities(res?.data?.fetchAllCities)
			return {
				options: res?.data?.fetchAllCities,
				hasMore: res?.data?.fetchAllCities?.length >= 1,
				additional: {
					page: searchQuery ? 2 : page + 1,
				}
			}
		});
	}

	const fetch_city = ({ id } = {}) => {
		return getCity({
			variables: {
				id: parseInt(id)
			}
		}).then((res) => {
			let data = res?.data?.fetchCity
			return data
		});
	}
	useEffect(() => {
		fetch_shipments({ search: shipmentSearchText })
		handleQueryChange({ shipmentSearchText: shipmentSearchText });
		setShipmentFilterUsed(true)

	}, [shipmentSearchText])

	useEffect(() => {
		fetch_drivers({ search: driverSearchText })
		handleQueryChange({ driverSearchText: driverSearchText });
	}, [driverSearchText])

	function clearUp({ courier, orders }) {
		// Update the statuses based on the response
		setSelectedDriver(prevDriver => ({ ...prevDriver, isBusy: courier.isBusy }));
		setSelectedShipments(prevShipments => prevShipments.map(shipment => {
			// Find the shipment in the response
			const updatedShipment = orders.find(order => order.id === shipment.id);
			// If found, update the status, else return the original
			console.log("updates, ", updatedShipment ? { ...shipment, orderStatus: updatedShipment.orderStatus, courier } : shipment)
			return updatedShipment ? { ...shipment, orderStatus: updatedShipment.orderStatus, courier: updatedShipment.courier || shipment.courier } : shipment;
		}));

	}

	useEffect(() => {
		const data = JSON.parse(localStorage?.getItem("filtersData"));
		if (data?.shipment && data.driver) {
			setFilters(data);
		}
	}, []);

	useEffect(() => {
		localStorage?.setItem("filtersData", JSON.stringify(filters));
	}, [filters]);

	useEffect(() => {
		try {
			let drivers = [];
			selectedShipments.map((el) => {
				if (el && el.courier) {
					const index = drivers.findIndex((d) => d.id === el.courier.id);
					if (index === -1) drivers.push(el.courier);
				}
			});
			setActiveDrivers(drivers);
			// const lastSelectedShipment = selectedShipments[0]

			// if (lastSelectedShipment && lastSelectedShipment.courier) {
			//   setActiveDrivers((prev) => [...prev, lastSelectedShipment.courier]);
			// }
		} catch (e) {
			toast.error(e.message);
		}
	}, [selectedShipmentIds]);

	useEffect(() => {
		if (filters.shipment.apply === true) {
			const fetchFilters = getShipmentFetchFilters(
				filters,
				shipmentSearchText
			);

			return setFilters((old) => ({
				...old,
				shipment: {
					...old.shipment,
					apply: false,
				},
			}));
		}
	}, [filters.shipment.apply]);

	return (
		<MapIsTypingContext.Provider value={{ isInputFocused, setIsInputFocused }}>
			<div className="flex h-screen w-full bg-lightBgColor justify-between overflow-hidden ">
				<div className={`w-[400px] max-w-[420px] px-5 pt-5`}>
					<ShipmentsList
						filterUsed={shipmentFilterUsed}
						setFilterUsed={setShipmentFilterUsed}
						totalShipmentCount={totalShipmentCount}
						handleUpdateShipmentList={handleUpdateShipmentList}
						handleQueryChange={handleQueryChange}
						setShipmentSkip={setShipmentSkip}
						fetch_hubs={fetch_hubs}
						fetch_merchants={fetch_merchants}
						fetch_merchant={fetch_merchant}
						fetch_shipments={fetch_shipments}
						fetch_cities={fetch_cities}
						fetch_city={fetch_city}
						setShipmentFilters={setProductFilters}
						shipmentFilters={productFilters}
						loading={shipmentLoading}
						shipmentListLoading={shipmentListLoading}
						cities={cities}
						merchants={merchants}
						hubs={hubs}
						activeShipments={activeShipments}
						mapBounds={mapBounds}
						shipments={allShipments}
						selectedShipmentIds={selectedShipmentIds}
						setSelectedShipmentIds={setSelectedShipmentIds}
						selectedShipments={selectedShipments}
						setSelectedShipments={setSelectedShipments}
						searchText={shipmentSearchText}
						setSearchText={setShipmentSearchText}
					/>
				</div>
				<LeafletMap
					mapBounds={mapBounds}
					handleMapBoundsChange={handleMapBoundsChange}
					handleAssign={() => handleAssignCourier(selectedDriverId)}
					mergeOrdersToShipment={handleMergeOrder}
					handleUnassign={handleUnassignCourier}
					filters={filters}
					setFilters={setFilters}
					shipments={allShipmentsNew}
					drivers={allDrivers}
					selectedShipmentIds={selectedShipmentIds}
					selectedDriver={selectedDriver}
					selectedShipments={selectedShipments}
					setSelectedShipments={setSelectedShipments}
					setSelectedShipmentIds={setSelectedShipmentIds}
					selectedDriverId={selectedDriverId}
					setSelectedDriverId={setSelectedDriverId}
					setSelectedDriver={setSelectedDriver}
					loading={driverLoading}
					areaSelectRegion={areaSelectRegion}
					setAreaSelectRegion={setAreaSelectRegion}
				/>
				<div className={`w-[400px] max-w-[420px] px-5 pt-5`}>
					<DriverList
						totalDriverCount={totalDriverCount}
						handleQueryChange={handleQueryChange}
						setDriverSkip={setDriverSkip}
						fetch_city={fetch_city}
						fetch_merchants={fetch_merchants}
						fetch_drivers={fetch_drivers}
						fetch_cities={fetch_cities}
						setDriverFilters={setProductFilters}
						driverFilters={productFilters}
						loading={driverLoading}
						driverListLoading={driverListLoading}
						activeDrivers={activeDrivers}
						drivers={allDrivers}
						setDrivers={setAllDrivers}
						setSelectedDriverId={setSelectedDriverId}
						selectedDriverId={selectedDriverId}
						setSelectedDriver={setSelectedDriver}
						selectedDriver={selectedDriver}
						cityIds={cityIds}
						mapBounds={mapBounds}
						searchText={driverSearchText}
						setSearchText={setDriverSearchText}
					/>
				</div>
			</div>
		</MapIsTypingContext.Provider>
	);
}
