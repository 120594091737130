import { useContext, useEffect } from "react";
import { MapContainer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { toast } from "react-toastify";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsIcon from "@mui/icons-material/Directions";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PinDropIcon from "@mui/icons-material/PinDrop";
import SelectArea from "leaflet-area-select";
import "leaflet/dist/leaflet.css";

import MapFilters from "./common/MapFilterButtons";
import FloatingActionMenu from "../../components/FloatingActionMenu";
import useLeafletMap from "./hooks/useLeafletMap";
import L from "../../helper/CustomLeaflet";
import FloatingInfoMenu from "../../components/common/FloatingMenu";
import MapIsTypingContext from "context/MapIsTypingContext";
import MapNavIcon from "./components/MapNavIcon";
import { MergeOrderIcon } from "components/svg";
export default function LeafletMap({
  handleAssign,
  handleUnassign,
  mergeOrdersToShipment,
  shipments,
  drivers,
  selectedShipmentIds,
  setSelectedShipmentIds,
  selectedShipments,
  setSelectedShipments,
  selectedDriverId,
  selectedDriver,
  setSelectedDriverId,
  setSelectedDriver,
  filters,
  setFilters,
  handleMapBoundsChange,
  areaSelectRegion,
  setAreaSelectRegion,
}) {
  const {
    map,
    MapControls,
    routeList,
    showShipmentMarkers,
    clearDropoffMarkers,
    showDriverMarkers,
    showShipmentListRoute,
    selectMarkersWithinRegion,
    selectShipmentsOnCheckbox,
    fitMapToBounds,
    removeShipmentRouteLayers,
    handleShowDropoffs,
    controlDropoffMode,
    isDropoffMode,
    dropoffMarkers,
    limitMessage,
    setLimitMessage,
  } = useLeafletMap({
    selectedShipmentIds,
    setSelectedShipmentIds,
    setSelectedShipments,
    selectedDriverId,
    setSelectedDriverId,
    setSelectedDriver,
    filters,
    drivers,
    shipments,
    selectedShipments,
  });

  const { isInputFocused } = useContext(MapIsTypingContext);

  const fitMapViewToSelectedElements = () => {
    let coordinates = [];

    if (filters.shipment.showOnMap) {
      selectedShipments.map((order) =>
        coordinates.push({
          latitude: order.origin.latitude,
          longitude: order.origin.longitude,
        })
      );
    }

    if (filters.driver.showOnMap) {
      if (selectedDriverId) {
        coordinates.push({
          latitude: selectedDriver.latitude,
          longitude: selectedDriver.longitude,
        });
      }
    }

    if (filters.dropoff.showOnMap) {
      selectedShipments.forEach((order) => {
        coordinates.push({
          latitude: order.destination.latitude,
          longitude: order.destination.longitude,
        });
      });
    }

    fitMapToBounds(map, coordinates);
  };

  useEffect(() => {
    if (limitMessage) {
      toast.error(limitMessage);
      setLimitMessage("");
    }
  }, [limitMessage]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!isInputFocused && e.altKey) {
        switch (e.key.toLowerCase()) {
          case "r":
            showSelectedShipmentsRouteOnMap();
            e.preventDefault();
            break;
          case "a":
            handleAssign();
            e.preventDefault();
            break;
          case "u":
            handleUnassign();
            e.preventDefault();
            break;
          case "f":
            fitMapViewToSelectedElements();
            e.preventDefault();
            break;
          case "m":
            mergeOrdersToShipment();
            e.preventDefault();
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    selectedShipmentIds,
    selectedDriverId,
    routeList,
    isInputFocused,
    dropoffMarkers,
  ]);

  // Adding tile: Google maps
  useEffect(() => {
    window.L.tileLayer("http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {
      maxZoom: 20,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    });
  }, []);

  // Check whether map has loaded
  useEffect(() => {
    if (map) {
      // enable are select SHIFT+CTRL+left click
      map.selectArea.enable();
      map.on("areaselected", (e) => {
        const selectedArea = L.rectangle(e.bounds);
        setAreaSelectRegion(selectedArea);
      });

      map.on(
        "moveend zoomend",
        (function () {
          if (areaSelectRegion) map.removeLayer(areaSelectRegion);

          let timer;
          return function () {
            clearTimeout(timer);
            timer = setTimeout(function () {
              handleMapBoundsChange(
                map.getBounds().getNorthEast().lat,
                map.getBounds().getNorthEast().lng,
                map.getBounds().getSouthWest().lat,
                map.getBounds().getSouthWest().lng
              );
            }, 500);
          };
        })()
      );
    }
  }, [map]);

  useEffect(() => {
    if (areaSelectRegion) {
      selectMarkersWithinRegion(areaSelectRegion);
    }
  }, [areaSelectRegion]);

  // add shipment markers to the map
  useEffect(() => {
    showShipmentMarkers();
  }, [shipments, selectedShipmentIds, map]);

  useEffect(() => {
    // clearDropoffMarkers()
    if (isDropoffMode) handleShowDropoffs();
  }, [selectedShipmentIds, selectedShipments, shipments, map]);

  useEffect(() => {
    selectShipmentsOnCheckbox();
    removeShipmentRouteLayers();
  }, [selectedShipmentIds]);

  useEffect(() => {
    showDriverMarkers();
  }, [filters.driver.showOnMap]);

  useEffect(() => {
    showShipmentMarkers();
  }, [filters.shipment.showOnMap]);

  useEffect(() => {
    controlDropoffMode(filters.dropoff.showOnMap);
  }, [filters.dropoff.showOnMap]);

  // add driver markers to the map
  useEffect(() => {
    showDriverMarkers();
  }, [drivers, selectedDriverId, map]);

  useEffect(() => {
    if (filters.driver.apply == true) {
      showDriverMarkers();
    }
  }, [filters.driver.apply]);

  const showSelectedShipmentsRouteOnMap = () => {
    // remove unselected routes
    removeShipmentRouteLayers();

    if (map && selectedShipments?.length > 0) {
      showShipmentListRoute(selectedShipments);
    } else {
      toast.error("Select at least one order to show the route");
    }
    // prevent map being undraggable
    if (map) {
      map.dragging.enable();
    }
  };

  // add selected driver to the map
  useEffect(() => {
    if (selectedDriverId) {
      const selectedDriver = drivers?.find(
        (driver) => driver.id === selectedDriverId
      );
    }
    // prevent map being undraggable
    if (map) {
      map.dragging.enable();
    }
  }, [selectedDriverId]);

  const floatingMenuActions = [
    {
      icon: <CenterFocusStrongIcon />,
      name: "Focus selected (alt + f)",
      handleClick: () => {
        fitMapViewToSelectedElements();
      },
    },
    {
      icon: <DirectionsIcon />,
      name: "Show Route (alt + r)",
      handleClick: () => {
        showSelectedShipmentsRouteOnMap();
      },
    },
    {
      icon: <DirectionsCarIcon />,
      name: "Assign (alt + a)",
      handleClick: () => handleAssign(),
    },
    {
      icon: <NotInterestedIcon />,
      name: "UnAssign (alt + u)",
      handleClick: () => handleUnassign(),
    },
    {
      icon: <MergeOrderIcon />,
      name: "Merge (alt + m)",
      handleClick: () => mergeOrdersToShipment(),
    },
  ];

  return (
    <div className="max-h-screen w-full flex-1 z-0 ">
      <div className="flex-1 shadow-md" style={{
        height: "45px",
        position: "relative",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        marginTop: "19px",
        marginBottom: "9px",
        borderRadius: "8px",
        justifyContent: "space-between"

      }}>
        <div style={{
          display: "flex",
          alignItems: "center",
        }}>
          <MapFilters filters={filters} setFilters={setFilters} />
          <FloatingActionMenu actions={floatingMenuActions} />
        </div>
        <FloatingInfoMenu actions={floatingMenuActions} />
      </div>
      <MapContainer
        center={[24.719127, 46.669663]} // Riyadh
        zoom={12}
        zoomControl={true}
        style={{ height: "calc(100vh - 73px)", position: "relative" }}
        tap={false}
        attributionControl={false}
      >
        <ReactLeafletGoogleLayer
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          type={"roadmap"}
        />

        {/*  Fix: On page reload, the map's container displays "0." */}
        {/* {selectedShipmentIds?.length && (
          <FloatingActionMenu actions={floatingMenuActions} />
        )} */}

        <MapControls />
      </MapContainer>
    </div>
  );
}
