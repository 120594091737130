import NormalSelect from "./common/Select/NormalSelect";
import SelectAsyncPaginate from "./common/Select/AsyncPaginateSelect";
import { logger } from "helper/logger";

const data = [
    {
        name: "Filter by Status",
        value: "status",
        id: 2,
        options: [
            { value: "0", label: "Unassigned" },
            { value: "1", label: "Assigned" },
            { value: "2", label: "On Hold" },
            { value: "3", label: "Not Ready" },
            { value: "4", label: "Completed" },
            { value: "5", label: "Cancelled" },
            { value: "6", label: "Courier Arrived" },
            { value: "7", label: "Unaccepted" },
        ],
    },
];

export default function FiltersList ({ filters, handleChange, selectedFilters, lockedFields = [] }) {
    logger("filters changes", filters, selectedFilters)
    return (
        <div className="w-full flex flex-col space-y-5 mb-3 mt-5">
            <div className="flex flex-wrap pb-0">
                {
                    filters?.map((filter) => (
                        <div key={filter.id} className={`flex w-full p-3 ${lockedFields?.indexOf(filter.value) >= 0 && "disabledContent"}`}>
                            {!filter.isAsync ?

                                <NormalSelect
                                    key={filter.id}
                                    filter={filter}
                                    defaultValue={
                                        !filter.isMulti
                                            ? filter?.options?.find(option => option?.value == selectedFilters[filter?.value])
                                            : Array.isArray(selectedFilters[filter?.value])
                                                ? selectedFilters[filter?.value].map(option2 => filter?.options?.find(option => option?.value == option2))
                                                : null
                                    }
                                    handleChange={(e) => { handleChange(e.value, filter.value) }}
                                // isDisabled={lockedFields?.indexOf(filter.value) >= 0}
                                /> :
                                <SelectAsyncPaginate
                                    isSearchable={filter.isSearchable}
                                    key={filter.id}
                                    onChange={(e) => {
                                        handleChange(e.value, filter.value)
                                    }}
                                    // defaultValue={{ label: "q", value: 'asdsa' }}
                                    // value={() => filter?.getSelectedVal(selectedFilters[filter?.value])}
                                    getDefaultValue={() => {
                                        return filter?.getSelectedVal(selectedFilters[filter?.value])
                                    }}
                                    loadMore={filter.loadMore}
                                    filterName={filter.name}
                                    cacheUniqs={filter?.cacheUniqs}
                                    isMulti={filter?.isMulti}
                                // getOptionLabel={(option) => option.name}
                                // getOptionValue={(option) => option.id}
                                // isSearchable={true}
                                />}
                        </div>

                        //   <SelectAsyncPaginate
                        //     id="cityId"
                        //     value={shipmentFilters.city}
                        //     onChange={(option) => {
                        //       logger("ASYNC", option);
                        //       setShipmentFilters((old) => ({
                        //         ...old,
                        //         city: option,
                        //       }));
                        //     }}
                        //     loadMore={(searchQuery, page) =>
                        //       fetch_cities({ page: page, searchQuery: searchQuery })
                        //     }
                        //     filterName={"Select City"}
                        //     // getOptionLabel={(option) => option.name}
                        //     // getOptionValue={(option) => option.id}
                        //     // isSearchable={true}
                        //   />
                    ))}
            </div>
        </div>
    );
}
