import React, { useContext, useEffect, useRef, useState } from "react";
import SearchBar from "../../components/common/SearchBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, CircularProgress } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import MapFilterMenu from "./common/MapFilterMenu";
import CustomButton from "../../components/common/Button";
import useShipmentList from "./hooks/useShipmentList";
import SelectedShipmentsList from "./components/SelectedShipmentsList";
import AllShipmentsList from "./components/AllShipmentsList";
import Modal from "../../components/common/Modal";
import ModalFooter from "../../components/common/Modal/ModalFooter";
import { defaultShipmentMapFilters } from "../../constants/filters";
import FiltersList from "../../components/FiltersList";
import DiscreteSliderLabel from "./components/SliderFilter";
import { orderAgeMarks, shipmentHubStatuses } from "constants/constants";
import { orderStatusTypes, shipmentTypes } from "constants/enums";
import MapIsTypingContext from "context/MapIsTypingContext";
import { ArrowUpIcon, ArrowDownIcon, ResetIcon } from "components/svg";
import { useLazyQuery } from "@apollo/client";
import { stagingOptions } from "../../constants/apollo";
import { GET_ALL_HUBS_FOR_FILTER } from "../../api/queries/FilterQueries";
const days = {
  1: 1,
  2: 2,
  3: 3,
  4: 7,
  5: 14,
  6: 21,
  7: 30,
  8: 60,
  9: 0,
};
const daysReverse = {
  1: 1,
  2: 2,
  3: 3,
  7: 4,
  14: 5,
  21: 6,
  30: 7,
  60: 8,
  0: 9,
};

function ShipmentsList({
  handleQueryChange,
  setShipmentSkip,
  fetch_shipments,
  fetch_hubs,
  fetch_merchants,
  fetch_merchant,
  setShipmentFilters,
  shipmentFilters,
  setSelectedShipmentIds,
  selectedShipmentIds,
  selectedShipments,
  setSelectedShipments,
  shipments,
  hubs,
  reloadingShipment,
  mapBounds,
  searchText,
  setSearchText,
  loading,
  totalShipmentCount,
  setFilterUsed,
  shipmentListLoading,
}) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [shipmentFilterApplied, setShipmentFilterApplied] = useState(false);
  const [getHubs] = useLazyQuery(GET_ALL_HUBS_FOR_FILTER, stagingOptions);
  const {
    t,
    selectedRef,
    filterAreaOpen,
    setFilterAreaOpen,
    pinnedFilterIds,
    setPinnedFilterIds,
    handleShipmentClick,
    handleDeselectAllItems,
    limitMessage,
    setLimitMessage,
  } = useShipmentList({
    reloadingShipment,
    setSelectedShipmentIds,
    setSelectedShipments,
    searchText,
    setSearchText,
  });

  const topDiv = useRef(null);
  const bottomDiv = useRef(null);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { setIsInputFocused } = useContext(MapIsTypingContext);
  useEffect(() => {
    fetch_shipments({
      load: false,
      isShipmentFilterModalActive: shipmentFilterApplied,
    });
    setShipmentSkip(0);
  }, [mapBounds]);

  useEffect(() => {
    if (limitMessage) {
      toast(limitMessage, {
        type: "error",
      });
      setLimitMessage("");
    }
  }, [limitMessage]);

  // useEffect(() => {
  //   let myInterval = setInterval(
  //     () => fetch_shipments({ load: false, filters: shipmentFilters }),
  //     30000
  //   );
  //   return () => clearInterval(myInterval);
  // }, [mapBounds]);

  useEffect(() => {
    setFetchMoreLoading(false);
  }, [shipments]);

  useEffect(() => {
    setPinnedFilterIds(localStorage?.getItem("pinnedFilterIds"));
  }, []);

  useEffect(() => {
    setIsInputFocused(filterOpen);
  }, [filterOpen]);

  useEffect(() => {
    localStorage?.setItem("pinnedFilterIds", pinnedFilterIds);
  }, [pinnedFilterIds]);

  function handleFilter() {
    handleQueryChange(shipmentFilters);
    fetch_shipments({
      filters: shipmentFilters,
      isShipmentFilterModalActive: true,
    });
    setFilterUsed(true);
    setShipmentFilterApplied(true);
  }

  async function handleFilterReset() {
    handleQueryChange(defaultShipmentMapFilters);
    setShipmentFilters((old) => ({ ...old, ...defaultShipmentMapFilters }));
    fetch_shipments({
      filters: defaultShipmentMapFilters,
    });
    setFilterAreaOpen(false);
    setFilterOpen(false);
    setFilterUsed(true);
    setShipmentFilterApplied(false);
  }

  const handleScroll = () => {
    topDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  const handleScrollDown = () => {
    setFilterAreaOpen(false);
    bottomDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  return (
    <>
      <div>
        <div className="mb-6 flex justify-between items-center space-x-3 relative">
          <div className="flex space-x-3">
            <h2 className="pageTitle pt-1">Orders</h2>
            {shipmentListLoading && <CircularProgress size={28} />}
          </div>
          <div className="flex justify-between">

            <MapFilterMenu open={filterAreaOpen} setOpen={setFilterAreaOpen} />
            <SearchBar
              value={searchText}
              onChange={setSearchText}
              placeholder={t("search_shipments")}
            />
            <div onClick={handleScroll} className="flex justify-center">
              <div className="bg-success rounded-md cursor-pointer">
                <ArrowUpIcon />
              </div>
            </div>
            <div onClick={handleScrollDown} className="flex justify-center">
              <div className="bg-success rounded-md cursor-pointer">
                <ArrowDownIcon />
              </div>
            </div>
          </div>
        </div>
        <div className={`flex flex-col space-y-3 h-[calc(100vh-100px)] overflow-y-scroll scrollbar-custom-widget`}>
          <span ref={topDiv}></span>
          {filterAreaOpen && (
            <div className="pb-3 mr-1">
              <div className="p-0">
                Age of orders
                <div>
                  <DiscreteSliderLabel
                    min={1}
                    max={9}
                    marks={orderAgeMarks}
                    defaultVal={daysReverse[+shipmentFilters["shipmentReadySince"]]}
                    handleChange={(day) => {
                      setShipmentFilters((old) => ({
                        ...old,
                        shipmentReadySince: days[day],
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="bg-white rounded-md pt-1">
                <FiltersList
                  selectedFilters={shipmentFilters}
                  filters={[
                    {
                      name: "Filter by Status",
                      value: "shipmentOrderStatus",
                      id: 2,
                      options: orderStatusTypes,
                      isMulti: true,
                    },
                    {
                      name: "Filter by Merchant",
                      value: "shipmentMerchant",
                      id: 3,
                      isAsync: true,
                      isSearchable: true,
                      getSelectedVal: (id) => {
                        if (id && id != "all") {
                          return fetch_merchant({ id: id });
                        } else return { value: "all", label: "All" };
                      },
                      loadMore: (searchQuery, page) =>
                        fetch_merchants({ page: page, searchQuery: searchQuery }),
                    },
                    {
                      name: "Filter by Hub",
                      value: "hubIds",
                      id: 4,
                      isAsync: true,
                      isSearchable: true,
                      getSelectedVal: async (ids) => {
                        if (ids && ids != "all") {
                          const filteredIds = ids.filter(id => id !== "all").map(Number);
                          if (filteredIds.length) {
                            const response = await getHubs({ variables: { ids: filteredIds } });
                            return response?.data?.fetchAllHubs || { value: "all", label: "All" };
                          }
                        } else return { value: "all", label: "All" };
                      },
                      loadMore: (searchQuery, page) =>
                        fetch_hubs({
                          page,
                          searchQuery,
                          merchantId: shipmentFilters.shipmentMerchant,
                        }),
                      cacheUniqs: [shipmentFilters.shipmentMerchant],
                      isMulti: true,
                    },
                    {
                      name: "Filter by Shipment Type",
                      value: "shipmentType",
                      id: 5,
                      options: shipmentTypes,
                      isMulti: true,
                    },
                    {
                      name: "Filter by Hub Status",
                      value: "shipmentHubOpen",
                      id: 6,
                      options: shipmentHubStatuses,
                    },
                  ]}
                  handleChange={(filterVal, filterName) => {
                    setShipmentFilters((old) => ({
                      ...old,
                      [filterName]: filterVal,
                    }));
                  }}
                />
                <div className="flex gap-4 px-3 pb-3">
                  <div
                    onClick={handleFilterReset}
                    className="cursor-pointer gap-2 uppercase text-white font-bold bg-blue-700 hover:bg-blue-800 px-3 py-2 text-xs rounded-md w-full flex items-center justify-center">
                    <ResetIcon />
                    <span>Reset Filter</span>
                  </div>
                  <div
                    onClick={handleFilter}
                    className="cursor-pointer gap-2 uppercase text-white font-bold bg-green-700 hover:bg-green-800 px-3 py-2 text-xs rounded-md ">
                    Search
                  </div>
                </div>
              </div>
            </div>
          )}
          <SelectedShipmentsList
            handleShipmentClick={handleShipmentClick}
            data={selectedShipments}
            selectedRef={selectedRef}
            selectedShipmentIds={selectedShipmentIds}
            handleDeselectAllItems={handleDeselectAllItems}
            selectedShipments={selectedShipments}
          />
          {!loading && (
            <AllShipmentsList
              data={shipments}
              selectedRef={selectedRef}
              handleShipmentClick={handleShipmentClick}
              selectedShipmentIds={selectedShipmentIds}
              selectedShipments={selectedShipments}
            >
              {shipments?.length > 0 && (
                <div className="w-full flex items-center justify-center">
                  <CustomButton
                    onClick={() => {
                      setFetchMoreLoading(true);
                      const skip = shipments.length || 0;
                      setShipmentSkip(skip);
                      fetch_shipments({ skip: skip, load: false });
                    }}
                  >
                    {totalShipmentCount > shipments.length
                      ? fetchMoreLoading
                        ? "Loading..."
                        : "Load More"
                      : ""}
                  </CustomButton>
                </div>
              )}
            </AllShipmentsList>
          )}
          {loading && (
            <div className="mx-auto mt-10 flex flex-col items-center justify-center">
              <CircularProgress />
            </div>
          )}
          {shipments?.length == 0 && !loading && (
            <div className="mx-auto mt-10  flex flex-col items-center justify-center">
              {t("no_shipments")}
            </div>
          )}
          <span ref={bottomDiv}></span>
        </div>
      </div>
    </>
  );
}

export default React.memo(ShipmentsList);
