import React from "react";
import { SpeedDial } from "@mui/material";
import { SpeedDialIcon } from "@mui/material";
import { SpeedDialAction } from "@mui/material";
import MapNavIcon from "pages/OrdersMap/components/MapNavIcon";
export default function FloatingActionMenu ({ actions }) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(prev => !prev)
  }

  return (
    // <SpeedDial
    //   sx={{
    //     // position: "absolute",
    //     // right: "1px",
    //     // height: "40px"    
    //   }}
    //   ariaLabel="SpeedDial example"
    //   icon={<SpeedDialIcon />}
    //   onClick={handleClick}
    //   open={open}
    //   direction="left"
    // >
    //   {actions.map((action) => (
    //     <SpeedDialAction
    //       key={action.name}
    //       icon={action.icon}
    //       tooltipTitle={action.name}
    //       onClick={() => {
    //         action.handleClick();
    //         setOpen(false);
    //       }}
    //     />
    //   ))}
    // </SpeedDial>
    <div className="z-[400] flex items-center space-x-4">
      {actions.map((action) => (
        <MapNavIcon
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => {
            action.handleClick();
          }}
        />
      ))}
    </div>
  );
}
