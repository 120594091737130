import { useLazyQuery } from "@apollo/client";
import {
  FETCH_ORDER_FOR_MAP,
  FETCH_SHIPMENT_FOR_MAP,
} from "api/queries/Queries";
import { stagingOptions } from "constants/apollo";
import { logger } from "helper/logger";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  backgroundColors,
  borderColors,
  routeBgColors,
  routeColors,
  textColors,
} from "../../../components/colors";
import CustomCheckBox from "../../../components/common/Checkbox";
import CustomSlider from "../../../components/common/Slider";
import { getStatusColor } from "helper/getStatusColor";
import { EyeIcon, EyeSlashIcon } from "components/svg";

function OrderCard({
  data,
  color = "success",
  onClick = () => { },
  selectedShipmentIds,
  selectedShipments,
  selectedRef,
  inSelectedCards = false,
}) {
  const { t } = useTranslation("shipments");
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(inSelectedCards);
  const [shipmentData, setShipmentData] = useState(data);
  const [
    getShipment,
    { data: fetchedShipment, loading, error: shipmentsFetchError },
  ] = useLazyQuery(FETCH_ORDER_FOR_MAP, stagingOptions);

  // identify shipment status
  let statusColor = getStatusColor(data?.orderStatus); // default-unassigned, success-completed, danger-cancelled, warning-assigned
  let statusText = data?.orderStatus
    ?.split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  useEffect(() => {
    if (selectedShipmentIds) {
      if (selectedShipmentIds.indexOf(data?.id) !== -1) {
        setIsSelected(true);
        setIsOpen(true);
        getShipment({
          variables: {
            id: parseInt(data?.id),
          },
        });
      } else {
        setIsSelected(false);
        setIsOpen(false);
      }
    }
  }, [selectedShipments]);
  useEffect(() => {
    if (fetchedShipment) {
      setShipmentData(fetchedShipment?.fetchOrder);
    }
  }, [fetchedShipment]);

  if ((inSelectedCards && isSelected) || (!isSelected && !inSelectedCards))
    return (
      <div
        ref={
          selectedShipmentIds[selectedShipmentIds.length - 1] == data.id
            ? selectedRef
            : null
        }
        className={`select-none max-w-[383px] rounded-xl ${isSelected ? "bg-lightBlue" : "bg-white hover:bg-lightGray"
          } text-xs hover:cursor-pointer transition-all`}
        onClick={(e) => {
          onClick(data);
          setIsSelected(!isSelected);
        }}
      >
        <div
          className={`my-2 px-4 py-2 capitalize  flex items-center justify-between flex-col space-y-2`}
        >
          <div className="w-full flex justify-between">
            <div className="flex items-center justify-center space-x-1">
              {data?.number && (
                <span className="text-black block bg-white rounded-full h-4 w-4 text-center">
                  {data?.number}
                </span>
              )}
              <span
                className={`px-2 py-0.5 rounded-md text-white capitalize ${backgroundColors[statusColor]}`}
              >
                {shipmentData?.shipment?.id && <span className="rounded bg-gray-600 shadow-lg px-[4px] py-[2px] text-[9px] ml-[-3px] mr-[2px]">M</span>} {data?.tracking_no} / {statusText}
              </span>
              {isSelected && (
                <div
                  className={`w-2 h-2 border border-white rounded-full ${routeBgColors[data.id % routeBgColors.length || 0]
                    }`}
                ></div>
              )}
            </div>
            <span
              onClick={(e) => {
                e.stopPropagation();
                if (!isOpen) {
                  getShipment({
                    variables: {
                      id: parseInt(data?.id),
                    },
                  });
                }
                setIsOpen(!isOpen);
              }}
              className="text-primaryColor font-medium hover:underline"
            >
              {isOpen ? <EyeSlashIcon /> : <EyeIcon />}
            </span>
          </div>
          <div className="flex flex-row items-end justify-between text-left w-full space-x-1">
            <div className="flex flex-col w-1/3">
              {isOpen ? (
                <a
                  className="hover:underline"
                  target="_blank"
                  href={`${process.env.REACT_APP_MODE === "production" && isOpen
                    ? "https://barqfleet.com/admin/orders/" + shipmentData?.id
                    : "https://staging.barqfleet.com/admin/orders/" +
                    shipmentData?.id
                    }`}
                >
                  <span className="text-primaryColor">
                    {shipmentData?.id || ""}
                  </span>
                </a>
              ) : (
                <span className="text-primaryColor">
                  {shipmentData?.id || ""}
                </span>
              )}
              <span className="text-lightColor">Order ID</span>
            </div>

            <>
              <div className="flex flex-col w-1/3">
                <span>{shipmentData?.merchant?.name || ""}</span>
                <span className="text-lightColor">Merchant</span>
              </div>
              <div className="flex flex-col w-1/3">
                <span className="">{shipmentData?.neighborhood}</span>
                <span className="text-lightColor">Neighborhood</span>
              </div>
            </>
          </div>

          {isOpen && !loading && (
            <>
              <div className="flex flex-row items-end justify-between text-left w-full space-x-1">
                <div className="flex flex-col w-1/3">
                  {isOpen && shipmentData?.courier?.id ? (
                    <a
                      className="hover:underline text-primaryColor"
                      target="_blank"
                      href={`${process.env.REACT_APP_MODE === "production"
                        ? "https://barqfleet.com/admin/couriers/" +
                        shipmentData?.courier?.id
                        : "https://staging.barqfleet.com/admin/couriers/" +
                        shipmentData?.courier?.id
                        }`}
                    >
                      <span>
                        {shipmentData?.courier?.avatarUrl && (
                          <img
                            className="h-4 w-4 rounded-full border border-transparent bg-white"
                            src={shipmentData?.courier?.avatarUrl}
                          />
                        )}
                        {shipmentData?.courier?.firstName || ""}{" "}
                        {shipmentData?.courier?.lastName || "-"}
                      </span>
                    </a>
                  ) : (
                    <span>
                      {shipmentData?.courier?.avatarUrl && (
                        <img
                          className="h-4 w-4 rounded-full border border-transparent bg-white"
                          src={shipmentData?.courier?.avatarUrl}
                        />
                      )}
                      {shipmentData?.courier?.firstName || ""}{" "}
                      {shipmentData?.courier?.lastName || "-"}
                    </span>
                  )}
                  <span className="text-lightColor">
                    {t("assigned_knight")}
                  </span>
                </div>

                <div className="flex flex-col w-1/3">
                  <span
                    className={
                      textColors[
                      shipmentData?.hub?.isActive && shipmentData?.hub?.isOpen
                        ? "success"
                        : "danger"
                      ]
                    }
                  >
                    {shipmentData?.hub?.isActive && shipmentData?.hub?.isOpen
                      ? t("open")
                      : t("closed")}
                  </span>
                  <span className="text-lightColor">{t("hub_status")}</span>
                </div>
                <div className="flex flex-col w-1/3">
                  <span>
                    {shipmentData?.payment_type?.split("_")?.join(" ")}
                  </span>
                  <span className="text-lightColor">{t("payment_method")}</span>
                </div>
              </div>
              <div className="flex flex-row items-end justify-between text-left w-full space-x-1">
                <div className="flex flex-col w-1/3">
                  <span className="">
                    {shipmentData?.customerDetails?.mobile}
                  </span>
                  <span className="text-lightColor">Customer Phone</span>
                </div>

                <div className="flex flex-col w-1/3">
                  <a
                    className="hover:underline"
                    target="_blank"
                    href={`${process.env.REACT_APP_MODE === "production" && isOpen
                      ? "https://barqfleet.com/admin/hubs/" +
                      shipmentData?.hub?.id
                      : "https://staging.barqfleet.com/admin/hubs/" +
                      shipmentData?.hub?.id
                      }`}
                  >
                    <span className="text-primaryColor">
                      {shipmentData?.hub?.code}
                    </span>
                  </a>
                  <span className="text-lightColor">{t("hub_code")}</span>
                </div>
                <div className="flex flex-col w-1/3">
                  <span className={textColors[color]}>
                    {shipmentData?.invoiceTotal}
                  </span>
                  <span className="text-lightColor">Invoice total</span>
                </div>
              </div>
            </>
          )}
        </div>
        {/* {isOpen && !loading && (
                    <div className="my-4 px-4">
                        <CustomSlider data={shipmentData?.orders} />
                    </div>
                )} */}
      </div>
    );
  else return null;
}

export default React.memo(OrderCard);
