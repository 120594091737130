import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import {IconButton} from "@mui/material";
function MapNavIcon({ icon, tooltipTitle, onClick }) {
    const { t } = useTranslation("common");

    return (
        <div
            className=""
            onClick={onClick}
        >
            <Tooltip title={tooltipTitle}>
                <IconButton>
                    {icon}
                </IconButton>
            </Tooltip>
        </div>
    );
}

export default React.memo(MapNavIcon);
