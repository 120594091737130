import React, { useContext, useEffect, useState, useRef } from "react";
import SearchBar from "../../components/common/SearchBar";
import { logger } from "../../helper/logger";
import { CircularProgress } from "@mui/material";
import { LOAD_DRIVERS } from "../../api/queries/Queries";
import { useLazyQuery } from "@apollo/client";
import CustomSelect from "../../components/common/Select";
import MapFilterMenu from "./common/MapFilterMenu";
import CustomButton from "../../components/common/Button";
import useDriverList from "./hooks/useDriverList";
import ActiveDriversList from "./components/ActiveDriversList";
import AllDriversList from "./components/AllDriversList";
import { stagingOptions } from "../../constants/apollo";
import Modal from "../../components/common/Modal";
import ModalFooter from "../../components/common/Modal/ModalFooter";
import FiltersList from "../../components/FiltersList";
import { defaultDriverMapFilters } from "../../constants/filters";
import SelectedDriversList from "./components/SelectedDriversList";
import DiscreteSliderLabel from "./components/SliderFilter";
import {
  DRIVER_LAST_SEEN_MAX_MARK,
  DRIVER_LAST_SEEN_MIN_MARK,
  driverDeviceTypes,
  driverLastSeenMarks,
  driverStatusTypes,
  driverUniformTypes,
  driverVehicleTypes,
} from "constants/constants";
import MapIsTypingContext from "context/MapIsTypingContext";
import { ArrowUpIcon, ArrowDownIcon, ResetIcon } from "components/svg";

const days = {
  1: 1,
  2: 2,
  3: 3,
  4: 7,
  5: 14,
  6: 21,
  7: 30,
};
const daysReverse = {
  1: 1,
  2: 2,
  3: 3,
  7: 4,
  14: 5,
  21: 6,
  30: 7,
};

function DriverList({
  handleQueryChange,
  setDriverSkip,
  fetch_drivers,
  fetch_city,
  fetch_cities,
  setDriverFilters,
  driverFilters,
  activeDrivers,
  setSelectedDriverId,
  setSelectedDriver,
  selectedDriver,
  drivers,
  cityIds,
  setDrivers,
  selectedDriverId,
  searchText,
  setSearchText,
  loading,
  mapBounds,
  totalDriverCount,
  driverListLoading,
}) {
  const {
    t,
    updatePinnedFilters,
    filterAreaOpen,
    setFilterAreaOpen,
    pinnedFilterIds,
    setPinnedFilterIds,
    selectedRef,
    isLoading,
    setIsLoading,
    filterOptions,
    handleClick,
    updateFilters,
  } = useDriverList({
    selectedDriverId,
    setSelectedDriverId,
    setSelectedDriver,
    cityIds,
  });
  const topDiv = useRef(null);
  const bottomDiv = useRef(null);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const { setIsInputFocused } = useContext(MapIsTypingContext);

  function handleFilter() {
    handleQueryChange(driverFilters);
    fetch_drivers({
      filters: driverFilters,
    });
  }

  async function handleFilterReset() {
    handleQueryChange(defaultDriverMapFilters);
    setDriverFilters((old) => ({ ...old, ...defaultDriverMapFilters }));

    await fetch_drivers({
      filters: defaultDriverMapFilters,
    });
    setFilterAreaOpen(false);
    setFilterOpen(false);
  }

  useEffect(() => {
    let myInterval = setInterval(
      () => fetch_drivers({ load: false, filters: driverFilters }),
      15000
    );

    return () => clearInterval(myInterval);
  }, [driverFilters, mapBounds]);

  useEffect(() => {
    fetch_drivers({ load: false, filters: driverFilters });
    setDriverSkip(0);
  }, [driverFilters, mapBounds]);

  useEffect(() => {
    setIsInputFocused(filterOpen);
  }, [filterOpen]);

  useEffect(() => {
    setFetchMoreLoading(false);
  }, [drivers]);

  const handleScroll = () => {
    topDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  const handleScrollDown = () => {
    setFilterAreaOpen(false);
    bottomDiv.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  return (
    <>
      <div>
        <div className="mb-6 flex justify-between items-center space-x-3 relative">
          <div className="flex space-x-3">
            <h2 className="pageTitle pt-1">{t("knights")}</h2>
            {driverListLoading && <CircularProgress size={28} />}
          </div>
          <div className="flex justify-between">
            <MapFilterMenu open={filterAreaOpen} setOpen={setFilterAreaOpen} />
            <SearchBar
              placeholder={t("search_drivers")}
              onChange={setSearchText}
              value={searchText}
            />
            <div onClick={handleScroll} className="flex justify-center">
              <div className="bg-success rounded-md cursor-pointer">
                <ArrowUpIcon />
              </div>
            </div>
            <div onClick={handleScrollDown} className="flex justify-center">
              <div className="bg-success rounded-md cursor-pointer">
                <ArrowDownIcon />
              </div>
            </div>
          </div>
        </div>
        <div className={`flex flex-col space-y-3 h-[calc(100vh-100px)] overflow-y-scroll scrollbar-custom-widget pl-2`}>
          <span ref={topDiv}></span>
          {filterAreaOpen && (
            <>
              <div className="rounded-md flex flex-col space-x-1">
                <div className="flex flex-col">
                  {filterOptions?.map((filter) => {
                    if (pinnedFilterIds.includes(filter.id)) {
                      return (
                        <div className="flex p-3">
                          <CustomSelect
                            pinnedFilterIds={pinnedFilterIds}
                            key={filter.id}
                            filter={filter}
                            defaultValue={driverFilters.driver[filter.value]}
                            hasPin={false}
                            onPinClick={() => {
                              updatePinnedFilters(filter.id);
                            }}
                            handleChange={(selected) => {
                              setDriverFilters((old) => ({
                                ...old,
                                [filter.value]: selected,
                              }));
                            }}
                          />
                        </div>
                      );
                    }
                  })}
                </div>
                {pinnedFilterIds.length > 0 && (
                  <div className="flex px-3">
                    <CustomButton
                      className="flex-1"
                      onClick={() => setPinnedFilterIds([])}
                    >
                      {t("remove_filters")}
                    </CustomButton>
                  </div>
                )}
              </div>
              <div className="pb-3 mr-1">
                <div className="p-0">
                  Driver Last Seen
                  <div>
                    <DiscreteSliderLabel
                      min={DRIVER_LAST_SEEN_MIN_MARK}
                      max={DRIVER_LAST_SEEN_MAX_MARK}
                      marks={driverLastSeenMarks}
                      defaultVal={daysReverse[+driverFilters["driverLastSeen"]]}
                      handleChange={(day) => {
                        setDriverFilters((old) => ({
                          ...old,
                          driverLastSeen: days[day],
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="bg-white rounded-md pt-1">
                  <FiltersList
                    selectedFilters={driverFilters}
                    filters={[
                      {
                        name: "Filter by City",
                        value: "driverCity",
                        id: 1,
                        isAsync: true,
                        isSearchable: true,
                        getSelectedVal: (id) => {
                          if (id && id != "all") {
                            let sel = fetch_city({ id: +id });
                            return sel;
                          } else return { value: "all", label: "All" };
                        },
                        loadMore: (searchQuery, page) =>
                          fetch_cities({ page: page, searchQuery: searchQuery }),
                      },
                      {
                        name: "Filter by Vehicle",
                        value: "driverVehicle",
                        id: 2,
                        options: driverVehicleTypes,
                      },
                      {
                        name: t("filter_by_device"),
                        value: "driverDevice",
                        id: 4,
                        options: driverDeviceTypes,
                      },
                      {
                        name: t("filter_by_status"),
                        value: "driverStatus",
                        id: 7,
                        options: driverStatusTypes,
                      },
                    ]}
                    handleChange={(filterVal, filterName) => {
                      setDriverFilters((old) => ({
                        ...old,
                        [filterName]: filterVal,
                      }));
                    }}
                  />
                  <div className="flex gap-4 px-3 pb-3">
                    <div
                      onClick={handleFilterReset}
                      className="cursor-pointer flex items-center justify-center gap-2 uppercase text-white font-bold bg-blue-700 hover:bg-blue-800 px-3 py-2 text-xs rounded-md w-full">
                      <ResetIcon />
                      <span>Reset Filter</span>
                    </div>
                    <div
                      onClick={handleFilter}
                      className="cursor-pointer uppercase text-white font-bold text-center bg-white rounded-md bg-green-700 hover:bg-green-800 px-3 py-2 text-xs font-medium">
                      Search
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <SelectedDriversList
            selectedDriver={selectedDriver}
            handleClick={(id) => handleClick(id)}
            selectedRef={selectedRef}
          />
          {activeDrivers.length > 0 && (
            <ActiveDriversList
              drivers={activeDrivers}
              selectedDriver={selectedDriver}
              handleClick={(id) => handleClick(id)}
              selectedRef={selectedRef}
            />
          )}
          {!loading && (
            <AllDriversList
              drivers={drivers}
              selectedDriverId={selectedDriverId}
              handleClick={(id) => handleClick(id)}
              selectedRef={selectedRef}>
              {drivers?.length > 0 && (
                <div className="w-full flex items-center justify-center">
                  <CustomButton
                    onClick={() => {
                      setFetchMoreLoading(true);
                      const skip = drivers.length || 0;
                      setDriverSkip(skip);
                      fetch_drivers({ skip: skip, load: false });
                    }}
                  >
                    {totalDriverCount > drivers.length
                      ? fetchMoreLoading
                        ? "Loading..."
                        : "Load More"
                      : ""}
                  </CustomButton>
                </div>
              )}
            </AllDriversList>
          )}
          {loading && (
            <div className="mt-10 w-full flex items-center justify-center">
              <CircularProgress />
            </div>
          )}
          {!loading && drivers?.length == 0 && (
            <div className="mt-10 w-full flex items-center justify-center">
              <span>{t("no_drivers")}</span>
            </div>
          )}
          <span ref={bottomDiv}></span>
        </div>
      </div>
    </>
  );
}

export default React.memo(DriverList);
