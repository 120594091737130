import React from "react";
import { GroupOfOrdersIcon, OrderIcon, AvailableDriverIcon, BusyDriverIcon, InfoIcon, OfflineDriverIcon, StackedShipmentsIcon, StackedDriversIcon, DropOffIcon } from "../../svg";

export default function FloatingInfoMenu () {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev)
    }
    return (
        <div
        className={`pr-2 rounded-xl py-2  z-[400] items-start space-y-4 flex flex-col-reverse cursor-pointer`}
        >
            <div
                className="p-2 bg-white rounded-md my-2 cursor-pointer shadow-sm"
                onClick={handleClick}
                open={open}
                direction="up"
            >
                <InfoIcon />
            </div>
            {/* {actions.map((action) => (
        <divAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => {
            action.handleClick();
            handleClose();
          }}
        />
      ))} */}
            {open &&
                <div className="absolute bg-white rounded-md flex flex-col p-3 space-y-3 items-start shadow-md"
                style={{top: "40px", left: "-230px"}}>
                    <span className="text-[16px] text-gray-500">Map Legends</span>
                    <div className="flex items-center justify-center space-x-2">
                        <span><StackedShipmentsIcon /></span><span className="uppercase font-[500] text-[15px]">Stacked Shipments</span>
                    </div>
                    <div className="flex items-center justify-center space-x-2">
                        <span><StackedDriversIcon /></span><span className="uppercase font-[500] text-[15px]">Stacked Drivers</span>
                    </div>
                    <div className="h-[1px] w-full bg-gray-300"></div>

                    <div className="flex items-center justify-center space-x-2">
                        <span><GroupOfOrdersIcon /></span><span className="uppercase font-[500] text-[15px]">group of shipments</span>
                    </div>
                    <div className="flex items-center justify-center space-x-2">
                        <span><OrderIcon /></span><span className="uppercase font-[500] text-[15px]">single shipment</span>
                    </div>
                    <div className="h-[1px] w-full bg-gray-300"></div>

                    <div className="flex items-center justify-center space-x-2">
                        <span><AvailableDriverIcon /></span><span className="uppercase font-[500] text-[15px]">available driver</span>
                    </div>
                    <div className="flex items-center justify-center space-x-2">
                        <span><BusyDriverIcon /></span><span className="uppercase font-[500] text-[15px]">busy driver</span>
                    </div>
                    <div className="flex items-center justify-start space-x-2">
                        <span className="block scale-[70%] -ml-1 -mr-2"><OfflineDriverIcon /></span><span className="uppercase font-[500] text-[15px]">offline driver</span>
                    </div>
                    <div className="h-[1px] w-full bg-gray-300"></div>

                    <div className="flex items-center justify-center space-x-2">
                        <span><DropOffIcon /></span><span className="uppercase font-[500] text-[15px]">dropoff</span>
                    </div>
                </div>
            }
        </div>
    );
}
