

export const defaultShipmentMapFilters = {
    shipmentMerchant: "all",
    shipmentType: ["all"],
    shipmentOrderStatus: ["ready_for_delivery",],
    shipmentHubOpen: "all",
    shipmentReadySince: 0,
    hubIds: ["all"],
}

export const defaultDriverMapFilters = {
    driverCity: "all",
    driverDevice: "all",
    driverVehicle: "all",
    driverUniform: "all",
    driverStatus: "all",
    driverLastSeen: 30,
}

export const defFilters = {
    ...defaultShipmentMapFilters,
    ...defaultDriverMapFilters
};

