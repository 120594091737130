import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import ShipmentCard from "../../../components/ShipmentCard";
import OrderCard from "./OrderCard";

function AllShipmentsList ({
  data,
  selectedRef,
  handleShipmentClick,
  selectedShipmentIds,
  children,
  selectedShipments,
}) {
  const { t } = useTranslation("common");

  return (
    <>
      <div className={`flex items-center gap-4 ${cn({ hidden: data?.length == 0 })}`}>
        <h3>
          All orders
        </h3>
        <span className="text-orange-500 bg-white px-2 rounded-xl">{data?.length}</span>
      </div>
      {data?.map((shipment) => (
        <OrderCard
          selectedRef={selectedRef}
          key={shipment?.id}
          data={shipment}
          onClick={(shipment) => handleShipmentClick(shipment)}
          selectedShipmentIds={selectedShipmentIds}
          selectedShipments={selectedShipments}
        />
      ))}
      {children}
    </>
  );
}

export default React.memo(AllShipmentsList);
